html, body, #root {
  user-select: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 20px !important;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #bababa #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #bababa;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px !important;
}

.ant-tabs-tab-btn {
  text-shadow: none;
  font-size: 14px;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 10px;
}